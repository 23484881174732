import React, { Component } from 'react'

import { NavLink } from 'react-router-dom'
import withSizes from 'react-sizes'

import './style.scss'
import classNames from 'classnames'
import CloseIcon from '../close-icon'

class NavBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showInfo: false
    }

    this.toggleInfo = this.toggleInfo.bind(this)
  }

  toggleInfo () {
    this.setState({
      showInfo: !this.state.showInfo
    })
  }

  render () {
    return <div className={classNames('nav-bar-container')}>
      <div className={classNames('info-button')} onClick={this.toggleInfo}>
        <div className={classNames('info-button__text')}>info</div>
      </div>
      <div className={classNames('info', { 'info--show': this.state.showInfo })}>
        <div className={classNames('info__close')} onClick={this.toggleInfo}>
          <CloseIcon />
        </div>
        <div className={classNames('info__content')}>
          <div className={classNames('info__section')}>
            3448 Ashwood Avenue<br />
            Los Angeles, CA 90066<br /><br />
            310 / 745 3545 (T)<br />
            310 / 745 3012 (F)<br /><br />
          </div>
          <div className={classNames('info__section')}>
            <a href='mailto:jeff@guga.la' className={classNames('info__link')}>Contact</a><br />
            <a href='https://www.instagram.com/guga.architecture/' target='_blank' className={classNames('info__link')}>Instagram</a>
          </div>
        </div>
      </div>
      <nav className={classNames('nav-bar')}>
        <div className={classNames('nav-bar__home')}>
          {this.props.isSmall
            ? <div className={classNames('nav-bar__home-logo')}> 
              ga
            </div>
            : <NavLink to='/' className={classNames('nav-bar__home-logo')}>
              ga
            </NavLink>
          }
        </div>
        <div className={classNames('nav-bar__site-nav')}>
          <NavLink to='/projects' className={classNames('nav-bar__nav-item', 'nav-bar__nav-item--primary')} activeClassName={classNames('nav-bar__nav-item--active')}>
            projects
          </NavLink>
          <NavLink to='/press' className={classNames('nav-bar__nav-item', 'nav-bar__nav-item--primary')} activeClassName={classNames('nav-bar__nav-item--active')}>
            press
          </NavLink>
          <NavLink to='/everything' className={classNames('nav-bar__nav-item', 'nav-bar__nav-item--primary')} activeClassName={classNames('nav-bar__nav-item--active')}>
            everything
          </NavLink>
        </div>
        {!this.props.isSmall && <div className={classNames('nav-bar__secondary')}>
          <div className={classNames('nav-bar__address')}>
            <div className={classNames('nav-bar__nav-item', 'nav-bar__nav-item--secondary')}>
              3448 Ashwood Avenue
            </div>
            <div className={classNames('nav-bar__nav-item', 'nav-bar__nav-item--secondary')}>
              Los Angeles, CA 90066
            </div>
          </div>
          <div className={classNames('nav-bar__phone')}>
            <div className={classNames('nav-bar__nav-item', 'nav-bar__nav-item--secondary')}>
              310 / 745 3545 (T)
            </div>
            <div className={classNames('nav-bar__nav-item', 'nav-bar__nav-item--secondary')}>
              310 / 745 3012 (F)
            </div>
          </div>
          <div className={classNames('nav-bar__external-nav', 'nav-bar__nav-item--secondary')}>
            <a href='mailto:jeff@guga.la' className={classNames('nav-bar__nav-item', 'nav-bar__nav-item--secondary')}>
              Contact
            </a>
            <a href='https://www.instagram.com/guga.architecture/' target='_blank' className={classNames('nav-bar__nav-item', 'nav-bar__nav-item--secondary')}>
              Instagram
            </a>
          </div>
        </div>}
      </nav>
    </div>
  }
}

const mapSizesToProps = ({ width }) => ({
  isSmall: width < 900
})

export default withSizes(mapSizesToProps)(NavBar)
