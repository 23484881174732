
import React, { Component } from 'react'
import './style.scss'
import SlideShow from '../../components/slide-show'
import withSizes from 'react-sizes'

import classNames from 'classnames'

class Home extends Component {

  constructor () {
    super()

    this.state = {
      cursorTransform: '',
      hideCursor: true,
      cursorDirection: 'left'
    }

    this.slideshow = React.createRef()
    this.updateCursor = this.updateCursor.bind(this)
    this.hideCursor = this.hideCursor.bind(this)
    this.showCursor = this.showCursor.bind(this)
    this.onCursorClick = this.onCursorClick.bind(this)
    this.onSlideshowClick = this.onSlideshowClick.bind(this)
  }

  onSlideshowClick (e) {
    if (this.props.isSmall) {
      this.slideshow.current.nextSlide()
    }
  }

  onCursorClick (e) {
    if (this.state.cursorDirection === 'left') {
      this.slideshow.current.previousSlide()
    } else {
      this.slideshow.current.nextSlide()
    }
  }

  updateCursor (e) {
    const clientWidth = e.currentTarget.clientWidth
    const cursorDirection = (e.clientX < (clientWidth / 2)) ? 'left' : 'right'
    this.setState({
      cursorDirection,
      cursorTransform: `translate(calc(-50% + ${e.clientX}px), calc(-50% + ${e.clientY}px))`
    })
  }

  hideCursor (e) {
    this.setState({
      hideCursor: true
    })
  }

  showCursor (e) {
    this.setState({
      hideCursor: false
    })
  }

  render() {
    let slides = []
    slides = this.props.gallery.map((slide) => ({
      src: slide.image && slide.image.url,
      children: <div className={classNames('home__slide-caption-container')}>
        <div className={classNames('home__slide-caption')}>
          {slide.caption}
        </div>
      </div>
    }))

    return (
      <div className={classNames('home')} onMouseMove={this.updateCursor} onMouseLeave={this.hideCursor} onMouseEnter={this.showCursor}>
        <div className={classNames('home__cursor', { 'home__cursor--hidden': this.state.hideCursor })} style={{ transform: this.state.cursorTransform }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 218.12 146.12" className={classNames('slideshow__cursor-image', { 'slideshow__cursor-image--left': this.state.cursorDirection === 'left', 'slideshow__cursor-image--right': this.state.cursorDirection === 'right' })} onClick={this.onCursorClick}>
            <g>
              <polyline shape-rendering="crispEdges" points="74.12 145.06 2.12 73.06 74.12 1.06" />
              <line shape-rendering="crispEdges" x1="218.12" y1="73.06" x2="2.12" y2="73.06" />
            </g>
          </svg>
        </div>
        <div className={classNames('home__slide-show')}>
          <SlideShow ref={this.slideshow} slides={slides} auto onClick={this.onSlideshowClick}/>
        </div>
      </div>
    )
  }
}

const mapSizesToProps = ({ width }) => ({
  isSmall: width < 900
})

export default withSizes(mapSizesToProps)(Home)
