import Config from './config'

export default {
  getProjects () {
    const requestURL = Config.API_URL + '/projects.json'
    const req = {
      method: 'get',
      url: requestURL
    }

    return window.fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getProject (projectId) {
    const requestURL = Config.API_URL + `/projects/${projectId}.json`
    const req = {
      method: 'get',
      url: requestURL
    }

    return window.fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getGallery (title) {
    const requestURL = Config.API_URL + `/galleries/${title}.json`
    const req = {
      method: 'get',
      url: requestURL
    }

    return window.fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

  getFeaturedProjects () {
    return this.getProjects().then((res) => {
      const projects = res.data
      return projects.filter((project) => {
        return project.featured[0] && project.featured[0].selected
      })
    })
  },

  getArticles () {
    const requestURL = Config.API_URL + '/articles.json'
    const req = {
      method: 'get',
      url: requestURL
    }

    return window.fetch(req.url, req).then((response) => {
      return response.json()
    })
  },

}
