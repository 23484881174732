
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import CloseIcon from '../close-icon'
import anime from 'animejs'

import './style.scss'
import classNames from 'classnames'

import withSizes from 'react-sizes'

class EverythingProject extends Component {
  constructor (props) {
    super(props)
    this.node = React.createRef()
    this.scrollToPhotos = this.scrollToPhotos.bind(this)
  }

  scrollToPhotos () {
    if (this.node && this.node.current) {
      anime({
        targets: this.node.current,
        scrollTop: window.innerHeight,
        duration: 800,
        easing: 'easeInOutQuart'
      })
    }
  }

  render () {
    const { project, isSmall } = this.props

    let photos = []
    if (project && project.images) {
      photos = project.images.map((item, index) => (
        <img className={classNames('everything-project__photo', 'lazyload')} data-src={item.image && item.image.url} alt='' key={index} />
      ))
    }
    const bpHeight = project.blueprint && parseInt(project.blueprint.height)
    const bpWidth = project.blueprint && parseInt(project.blueprint.width)

    return (<div className={classNames('everything-project-container')} ref={this.node}>
      <div className={classNames('everything-project')}>
        <div className={classNames('everything-project__bg')} />
        <Link to='/everything' className={classNames('everything-project__close')}>
          <CloseIcon strokeWidth={0.5} />
        </Link>

        {isSmall && <div className={classNames('everything-project__scroll')} onClick={this.scrollToPhotos} />}

        {/* Think about class names here. everything-project__main, everything-project__info-container ? */}
        <div className={classNames('everything-project__info')}>
          <div className={classNames('everything-project__blueprint')} style={{ height: bpHeight, width: bpWidth }}>
            {project.blueprint && (<div style={{ backgroundImage: `url(${project.blueprint.url})` }} className={classNames('everything-project__blueprint-img', 'js-blueprint')} alt='' />)}

            {!isSmall && <div className={classNames('everything-project__info-container')} >
              <div className={classNames('everything-project__title')}>
                {project.title}
              </div>
              <div className={classNames('everything-project__info-text')}>
                Project type: {project.projectType || ''} <br />
                Area: {project.area || ''} <br />
                Status: {project.status || ''}
              </div>
            </div>}

          </div>

          {isSmall && <div className={classNames('everything-project__title')}>
            {project.title}
          </div>}
          {isSmall && <div className={classNames('everything-project__info-text')}>
            Project type: {project.projectType || ''} <br />
            Area: {project.area || ''} <br />
            Status: {project.status || ''}
          </div>}

        </div>
        <div className={classNames('everything-project__photos')}>
          {photos}
        </div>
      </div>
    </div>)
  }
}

EverythingProject.defaultProps = {
  project: {}
}

const mapSizesToProps = ({ width }) => ({
  isSmall: width < 900
})

export default withSizes(mapSizesToProps)(EverythingProject)
