
import React, { Component, createRef, useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CloseIcon from '../close-icon'
import debounce from 'lodash/debounce'
import SlideShow from '../slide-show'
import withSizes from 'react-sizes'
import anime from 'animejs'

import './style.scss'
import classNames from 'classnames'

const stripHTML = (htmlString) => {
  const temporalDivElement = document.createElement('div')
  temporalDivElement.innerHTML = htmlString || ''
  return temporalDivElement.textContent || temporalDivElement.innerText || ''
}

function useWindowSize () {
  const isClient = typeof window === 'object'

  function getSize () {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    }
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false
    }

    function handleResize () {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

// Not shown on small screens
let InfoBar = ({ isSmall, project, onClick, collapsed }) => {
  const projecTitleLower = project.title ? project.title.toLowerCase() : null
  const projectDescription = stripHTML(project.description)

  const elem = useRef(null)
  const [height, setHeight] = useState('10rem')
  const size = useWindowSize()

  useEffect(() => {
    if (elem.current && !collapsed) {
      setHeight(`${elem.current.scrollHeight}px`)
    } else {
      setHeight('1rem')
    }
  }, [collapsed, project, size])

  return <div className={classNames('info-bar', { 'info-bar--collapsed': collapsed })} onClick={collapsed ? onClick : undefined} ref={elem} style={{ height: height }}>
    <div className={classNames('info-bar__content')}>
      <div className={classNames('info-bar__container')}>
        <div className={classNames('info-bar__stats', { 'info-bar__stats--collapsed': collapsed })} >
          {!collapsed && <div onClick={onClick} className={classNames('info-bar__close', { 'info-bar__close--collapsed': collapsed })}>
            <CloseIcon strokeWidth={0.5} />
          </div> }
          <div className={classNames('info-bar__project-title', { 'info-bar__project-title--collapsed': collapsed })}>{projecTitleLower || ''}</div>
          <div className={classNames('info-bar__project-info', { 'info-bar__project-info--collapsed': collapsed })}>PROJECT TYPE: {project.projectType ? project.projectType.toUpperCase() : ''}</div>
          <div className={classNames('info-bar__project-info', { 'info-bar__project-info--collapsed': collapsed })}>AREA: {project.area ? project.area.toUpperCase() : ''}</div>
          <div className={classNames('info-bar__project-info', { 'info-bar__project-info--collapsed': collapsed })}>STATUS: {project.status ? project.status.toUpperCase() : ''}</div>
        </div>
        {!collapsed && <div className={classNames('info-bar__description')}>
          {projectDescription}
        </div>}
      </div>
    </div>
  </div>
}

InfoBar.defaultProps = {
  project: {}
}

const mapSizesToProps = ({ width }) => ({
  isSmall: width < 900
})

InfoBar = withSizes(mapSizesToProps)(InfoBar)

// Consider including in slideshow component, and turn on/off with props.
class ProjectDetailSlideshow extends Component {
  constructor (props) {
    super(props)

    this.state = {
      cursorTransform: '',
      hideCursor: true,
      cursorDirection: 'left'
    }

    this.elem = React.createRef()
    this.slideshow = React.createRef()
    this.updateCursor = this.updateCursor.bind(this)
    this.hideCursor = this.hideCursor.bind(this)
    this.showCursor = this.showCursor.bind(this)
    this.onSlideShowClick = this.onSlideShowClick.bind(this)
  }

  onSlideShowClick (e) {
    if (this.state.cursorDirection === 'left') {
      this.slideshow.current.previousSlide()
    } else {
      this.slideshow.current.nextSlide()
    }
  }

  updateCursor (e) {
    const clientWidth = e.currentTarget.clientWidth
    const cursorDirection = (e.clientX < (clientWidth / 2)) ? 'left' : 'right'
    this.setState({
      cursorDirection,
      cursorTransform: `translate(calc(-50% + ${e.clientX}px), calc(-50% + ${e.clientY}px))`
    })
  }

  hideCursor (e) {
    this.setState({
      hideCursor: true
    })
  }

  showCursor (e) {
    this.setState({
      hideCursor: false
    })
  }

  render () {
    const { slides } = this.props
    return <div className={classNames('slideshow')} onMouseMove={this.updateCursor} ref={this.elem} onMouseLeave={this.hideCursor} onMouseEnter={this.showCursor}>
      <div className={classNames('slideshow__cursor', { 'slideshow__cursor--hidden': this.state.hideCursor })} style={{ transform: this.state.cursorTransform }}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 218.12 146.12' className={classNames('slideshow__cursor-image', { 'slideshow__cursor-image--left': this.state.cursorDirection === 'left', 'slideshow__cursor-image--right': this.state.cursorDirection === 'right' })} onClick={this.onSlideShowClick}>
          <g>
            <polyline shape-rendering='crispEdges' points='74.12 145.06 2.12 73.06 74.12 1.06' />
            <line shape-rendering='crispEdges' x1='218.12' y1='73.06' x2='2.12' y2='73.06' />
          </g>
        </svg>
      </div>
      <SlideShow ref={this.slideshow} slides={slides} bgColor={'#e7b95a'} onClick={() => {}} />
    </div>
  }
}

class ProjectDetail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      infoBarCollapsed: false,
      slideShowZoomLevel: 8,
      infoBarHasCollapsed: false,
      initCollapseTimer: 10
    }

    this.node = createRef()
    this.info = createRef()

    this.timer = null

    this.onInfoBarClick = this.onInfoBarClick.bind(this)
    this.scrollToPhotos = this.scrollToPhotos.bind(this)
  }

  componentDidMount = () => {
    this.timer = setInterval(this.countDown, 1000)
  }

  countDown = () => {
    const remainingSeconds = this.state.initCollapseTimer - 1
    this.setState({
      initCollapseTimer: remainingSeconds
    })

    if (remainingSeconds === 0) {
      clearInterval(this.timer)

      if (!this.state.infoBarHasCollapsed) {
        this.setState({
          infoBarCollapsed: true
        })
      }
    }
  }

  onInfoBarClick = debounce(() => {
    if (!this.state.infoBarHasCollapsed) {
      this.setState({
        infoBarCollapsed: !this.state.infoBarCollapsed,
        infoBarHasCollapsed: true
      })
    } else {
      this.setState({
        infoBarCollapsed: !this.state.infoBarCollapsed
      })
    }
  }, 250)

  reprepareSlides = (slides) => {
    return slides.map((slide) => {
      return {
        src: null,
        bgColor: 'transparent',
        children: <div className={classNames('slide-img-container')}>
          <img src={slide.src} alt='' />
        </div>
      }
    })
  }

  scrollToPhotos () {
    if (this.node && this.node.current) {
      console.log(Math.max(window.innerHeight, this.info.current ? this.info.current.scrollHeight : 0))
      anime({
        targets: this.node.current,
        scrollTop: Math.max(window.innerHeight, this.info.current ? this.info.current.scrollHeight : 0),
        duration: 800,
        easing: 'easeInOutQuart'
      })
    }
  }

  render () {
    const { project, slides, isSmall } = this.props

    const infoHeight = Math.max(window.innerHeight, this.info.current ? this.info.current.scrollHeight : 0)

    let mainContent
    if (isSmall) {
      const photos = slides.map((slide, index) => (
        <img className={classNames('project-detail__photo', 'lazyload')} data-src={slide.src} alt='' key={index} />
      ))
      mainContent = <>
        <div className={classNames('project-detail__content')}>
          <div className={classNames('project-detail__scroll')} onClick={this.scrollToPhotos} />
          <div ref={this.info} className={classNames('project-detail__info')} style={{ height: infoHeight }}>
            <div className={classNames('project-detail__title')}>
              {project.title ? project.title.toLowerCase() : ''}
            </div>
            <div className={classNames('project-detail__description')}>
              {stripHTML(project.description)}
            </div>
            <div className={classNames('project-detail__stats')}>
              <div className={classNames('project-detail__stat')}><span>PROJECT TYPE:</span><span>{project.projectType ? project.projectType.toUpperCase() : ''}</span></div>
              <div className={classNames('project-detail__stat')}><span>AREA:</span><span>{project.area ? project.area.toUpperCase() : ''}</span></div>
              <div className={classNames('project-detail__stat')}><span>STATUS:</span><span>{project.status ? project.status.toUpperCase() : ''}</span></div>
            </div>
          </div>
          <div className={classNames('project-detail__photos')} style={{ top: infoHeight }}>
            {photos}
          </div>
        </div>
      </>
    } else {
      const preppedSlides = this.reprepareSlides(slides)
      mainContent = <>
        <div className={classNames('project-detail__slideshow')}>
          <ProjectDetailSlideshow slides={preppedSlides} isSmall={isSmall} />
        </div>
        <InfoBar project={project} collapsed={this.state.infoBarCollapsed} onClick={this.onInfoBarClick} />
      </>
    }

    return (<div className={classNames('project-detail')} ref={this.node}>
      <Link to='/projects' className={classNames('project-detail__close')}>
        <CloseIcon strokeWidth={0.5} />
      </Link>
      {mainContent}
    </div>)
  }
}

ProjectDetail.defaultProps = {
  project: {}
}

export default withSizes(mapSizesToProps)(ProjectDetail)
