
import React, { Component } from 'react'
import anime from 'animejs'

import { Link, Route, Switch } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import Transition from 'react-transition-group/Transition'
import EverythingProject from '../../components/everything-project'

import './style.scss'
import classNames from 'classnames'

export default class Everything extends Component {
  constructor (props) {
    super(props)
    this.state = {
      appear: false,
      filter: null
    }

    this.transitionTime = 500

    this.showCommercialProjects = this.showCommercialProjects.bind(this)
    this.showResidentialProjects = this.showResidentialProjects.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
  }

  /** FILTER **/
  filteredProjects (projects) {

    let filteredProjects = projects
    if (this.state.filter) {
      filteredProjects = projects.filter((project) => {
        const re = new RegExp(this.state.filter)
        return re.test(project.projectType)
      })
    }
    const sortedFilteredProjects = this.sortByExpandable(filteredProjects)
    return sortedFilteredProjects
  }

  sortByExpandable (projects) {
    return projects.sort((a, b) => {
      if (a.expandable.length && !b.expandable.length) {
        return -1
      } else if (b.expandable.length && !a.expandable.length) {
        return 1
      }
      return 0
    })
  }
  
  showCommercialProjects () {
    this.setState({
      filter: 'commercial'
    })
  }

  showResidentialProjects () {
    this.setState({
      filter: 'residential'
    })
  }

  clearFilter () {
    this.setState({
      filter: null
    })
  }

  /** END FILTER **/

  // Maybe breakup and refactor further
  // Remove pathname

  // Add more overall comments describing each animation action
  animateBlueprint (pathname, node, project, grow) {
    if (project) {
      const gridBp = document.getElementById(project.id).querySelector('.everything-grid-element__image')
      const detailBp = node.querySelector('.everything-project__blueprint-img')

      if (gridBp && detailBp) {
        gridBp.style.display = 'block'
        detailBp.style.display = 'block'
        gridBp.style.width = ''
        gridBp.style.height = ''
        detailBp.style.width = ''
        detailBp.style.height = ''

        const first = grow ? gridBp.getBoundingClientRect() : detailBp.getBoundingClientRect()
        // If we're growing we need to know how to translate from the small state.
        // Without this we get a jump because we've translated from the top left of the big
        // boundingClientRect() which would be further to the left than the small boundingClientRect()
        if (grow) {
          detailBp.style.width = `${first.width}px`
          detailBp.style.height = `${first.height}px`
        }
        let last = grow ? detailBp.getBoundingClientRect() : gridBp.getBoundingClientRect()

        const deltaX = first.left - last.left
        const deltaY = first.top - last.top

        if (grow) {
          detailBp.style.width = ''
          detailBp.style.height = ''
          last = detailBp.getBoundingClientRect()
        }

        // We're not using scale because of the way the size of the actual blueprint image
        // gets auto calculated by the browser to fit within its element.  This leads to
        // weird jumps in size on transition.
        // const deltaW = first.width / last.width
        // const deltaH = first.height / last.height

        let target
        if (grow) {
          gridBp.style.display = 'none'
          target = detailBp
        } else {
          detailBp.style.opacity = 0
          target = gridBp
        }

        target.style.transformOrigin = 'top left'
        target.style.zIndex = '20'

        const defaultEasing = 'easeInOutQuart'
        const transitionTime = this.transitionTime

        // Play: animate the final element from its first bounds
        // to its last bounds (which is no transform)
        const timeline = anime.timeline({
          easing: defaultEasing,
          duration: transitionTime,
          autoplay: false
        })

        timeline.add({
          targets: target,
          duration: 0,
          translateX: `${deltaX}px`,
          translateY: `${deltaY}px`,
          width: first.width,
          height: first.height
        })

        timeline.add({
          targets: target,
          translateX: 0,
          translateY: 0,
          width: last.width,
          height: last.height,
          zIndex: ''
        })

        // Use shared selector
        const fadeElements = node.querySelectorAll([
          '.everything-project__bg',
          '.everything-project__photos',
          '.everything-project__info-text',
          '.everything-project__close',
          '.everything-project__title',
          '.everything-project__scroll'
        ].join(','))

        const fadeTimeline = anime.timeline({
          easing: defaultEasing,
          duration: transitionTime,
          autoplay: false,
          targets: fadeElements
        })

        fadeTimeline.add({
          duration: 0,
          opacity: grow ? 0 : 1
        })

        fadeTimeline.add({
          targets: fadeElements,
          opacity: grow ? 1 : 0
        })

        if (grow) {
          timeline.play()
          timeline.finished.then(() => {
            fadeTimeline.play()
            detailBp.style.width = ''
            detailBp.style.height = ''
          })
        } else {
          fadeTimeline.play()
          fadeTimeline.finished.then(() => {
            timeline.play()
            timeline.finished.then(() => {
              gridBp.style.width = ''
              gridBp.style.height = ''
            })
          })
        }
      }
    }
  }

  componentDidMount () {
    this.setState({
      appear: true
    })
  }

  render () {
    // newline
    const gridElements = this.filteredProjects(this.props.projects).map((project, index) =>
      <EverythingGridElement key={index} project={project} detailLink={`${this.props.match.path}/${encodeURIComponent(project.id)}`} />
    )
    
    return (
      <div className={classNames('everything')}>
        {/* I think this can maybe be removed because we're not using a slideshow bg anymore */}
        <div className={classNames('everything__grid', 'js-content')}>
          <div className={classNames('everything__filter', 'js-content')}>
            <div className={classNames('everything__filter-item', 'js-content', { 'everything__filter-item--active': this.state.filter == null })} onClick={this.clearFilter}>
              all
            </div>
            <div className={classNames('everything__filter-item', 'js-content', { 'everything__filter-item--active': this.state.filter === 'commercial' })} onClick={this.showCommercialProjects}>
              commercial
            </div>
            <div className={classNames('everything__filter-item', 'js-content', { 'everything__filter-item--active': this.state.filter === 'residential' })} onClick={this.showResidentialProjects}>
              residential
            </div>
          </div>
          <div className={classNames('everything__grid-inner')}>
            {gridElements}
          </div>
        </div>
        {/*
          We need this outer route so that we can define the transition onEnter animation
          with the project. Otherwise we don't have the project until we're inside the
          transition which means that it can't be passed to the onEnter prop and we won't
          know which grid element to animate.
        */}
        <Route path='/everything/:id?' render={(props) => {
          const project = this.props.projects.find(project => `${project.id}` === props.match.params.id)
          return (<TransitionGroup component={null}>
            <Transition
              key={props.location.key}
              appear={this.state.appear}
              onEnter={(node) => { this.animateBlueprint(props.location.pathname, node, project, true) }}
              onExit={(node) => { this.animateBlueprint(props.location.pathname, node, project, false) }}
              timeout={this.transitionTime}>
              <Switch location={props.location}>
                <Route path='/everything/:id' render={(props) => {
                  return <div className={classNames('everything__project-detail')}>
                    <EverythingProject project={project} />
                  </div>
                }} />
              </Switch>
            </Transition>
          </TransitionGroup>)
        }} />
      </div>
    )
  }
}

const EverythingGridElement = ({ project, detailLink }) => {
  if (project.expandable && project.expandable[0] && project.expandable[0].selected) {
    return <Link className={classNames('everything-grid-element')} to={detailLink} id={project.id}>
      {project.blueprint && <div data-bg={project.blueprint.url} className={classNames('everything-grid-element__image', 'lazyload')} alt='blueprint' />}
      <div className={classNames('everything-grid-element__title')}>{project.title}</div>
    </Link>
  } else {
    return <div className={classNames('everything-grid-element')} id={project.id}>
      {project.blueprint && <div data-bg={project.blueprint.url} className={classNames('everything-grid-element__image', 'lazyload')} alt='blueprint' />}
      <div className={classNames('everything-grid-element__title')}>{project.title}</div>
    </div>
  }
}
