import React, { Component } from 'react'
import './style.scss'
import classNames from 'classnames'

// TODO: Add an animation for switching slides!!!!
export default class SlideShow extends Component {
  nextSlide = () => {
    const slide = this.state.activeSlide + 1 < this.props.slides.length ? this.state.activeSlide + 1 : 0
    this.setActiveSlide(slide)
  }

  previousSlide = () => {
    const slide = this.state.activeSlide - 1 < 0 ? this.props.slides.length - 1 : this.state.activeSlide - 1
    this.setActiveSlide(slide)
  }

  setActiveSlide = (index) => {
    this.setState({
      activeSlide: index
    })

    window.clearTimeout(this.timeoutID)
    if (this.props.auto) {
      this.timeoutID = window.setTimeout(() => {
        this.nextSlide()
      }, this.props.slideInterval || 5000)
    }
  }

  constructor () {
    super()
    this.state = {
      activeSlide: 0
    }
  }

  componentDidMount () {
    if (this.props.auto) {
      this.timeoutID = window.setTimeout(() => {
        this.nextSlide()
      }, this.props.slideInterval || 5000)
    }
  }

  componentWillUnmount () {
    window.clearTimeout(this.timeoutID)
  }

  render () {
    const slides = this.props.slides.map((slide, index) => {
      return <Slide key={index} image={slide.src} bgColor={this.props.bgColor} active={index === this.state.activeSlide} onClick={this.props.onClick || this.nextSlide} children={slide.children} />
    })

    // TODO: Dot should be its own component
    let dots = []

    if (slides.length >= 2) {
      dots = this.props.slides.map((_, index) => {
        const isHorizontal = this.props.dotsHorizontal ? '__horiz' : ''
        const dotCustomStyle = {
          'borderColor': this.props.dotsColor,
          'width': this.props.dotsSize,
          'height': this.props.dotsSize,
          'margin': this.props.dotsMargin
        }
        dotCustomStyle['backgroundColor'] = (index === this.state.activeSlide) ? this.props.dotsColor : 'transparent'

        return <div key={index} style={dotCustomStyle} className={classNames('slide-show__dot' + isHorizontal, { 'slide-show__dot--active': index === this.state.activeSlide })} onClick={() => this.setActiveSlide(index)} />
      })
    }

    return (
      <div className={classNames({ 'slide-show': true, 'slide-show--full': this.props.full && !this.props.standalone, 'slide-show--full-standalone': this.props.full && this.props.standalone })}>
        {slides}
        {this.props.controls && <div className={classNames('slide-show__dots')} style={this.props.dotsStyle}>
          {dots}
        </div>}
      </div>
    )
  }
}

SlideShow.defaultProps = {
  slides: []
}

const Slide = ({ ...props }) => {
  const { image, bgColor, active, onClick } = props
  const slideStyle = {
    backgroundImage: 'url(' + image + ')',
    backgroundColor: bgColor
  }

  return (
    <div className={classNames({ 'slide-show__slide': true, 'slide-show__slide--active': active })} style={slideStyle} onClick={onClick}>
      {props.children}
    </div>
  )
}
