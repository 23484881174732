import React, { Component } from 'react'

import { TransitionGroup } from 'react-transition-group'
import Transition from 'react-transition-group/Transition'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { play, exit, transitionTime } from './timelines'
import Api from './api'

import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import withSizes from 'react-sizes'

import NavBar from './components/nav-bar'

// Code-splitting is automated for routes
import Everything from './routes/everything'
import Projects from './routes/projects'
import Home from './routes/home'
import About from './routes/about'

import './App.scss'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      projects: [],
      featuredProjects: [],
      homeGallery: [],
      articles: []
    }
  }

  componentDidMount () {
    // add simple support for background images:
    document.addEventListener('lazybeforeunveil', (e) => {
      var bg = e.target.getAttribute('data-bg')
      if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')'
      }
    })
    this.loadData()
  }

  loadData () {
    const dataPromises = [
      Api.getProjects(),
      Api.getGallery('Home'),
      Api.getArticles()
    ]

    Promise.all(dataPromises).then(values => {
      const featuredProjects = values[0].data.filter(project => (
        project.featured[0] && project.featured[0].selected
      )).sort((a, b) => {
        if (a?.sortOrderProjectsList == b?.sortOrderProjectsList) return 0
        if (a?.sortOrderProjectsList > b?.sortOrderProjectsList) return 1
        return -1
      }).reverse()

      const projects = values[0].data.sort((a, b) => {
        if (a?.sortOrderEverythingList == b?.sortOrderEverythingList) return 0
        if (a?.sortOrderEverythingList > b?.sortOrderEverythingList) return 1
        return -1
      }).reverse()

      this.setState({
        projects: projects,
        featuredProjects,
        homeGallery: values[1].images,
        articles: values[2].data
      })
    })
  }

  render () {
    return (
      <Router>
        <Route render={({ location }) => (
          <div id='app'>
            <NavBar />
            <Route path='/' render={props => {
              // Never show home for mobile
              if (this.props.isSmall && props.match.isExact) {
                return <Redirect to='/projects' />
              } else {
                return <div className='container'>
                  <TransitionGroup className='transition-group' component={null}>
                    <Transition
                      key={location.pathname.split('/')[1]}
                      appear
                      onEnter={(node, appears) => play(location.pathname, node, appears)}
                      onExit={(node, appears) => exit(location.pathname, node, appears)}
                      timeout={{ enter: transitionTime, exit: transitionTime }}
                    >
                      <Switch location={location}>
                        {!this.props.isSmall && <Route exact path='/' render={props => <Home {...props} gallery={this.state.homeGallery} />} />}
                        <Route path='/everything' render={props => <Everything {...props} projects={this.state.projects} />} />
                        <Route path='/projects' render={props => <Projects {...props} projects={this.state.featuredProjects} />} />
                        <Route path='/press' render={props => <About {...props} articles={this.state.articles} />} />
                      </Switch>
                    </Transition>
                  </TransitionGroup>
                </div>
              }
            }} />
          </div>
        )} />
      </Router>
    )
  }
}

const mapSizesToProps = ({ width }) => ({
  isSmall: width < 900
})

export default withSizes(mapSizesToProps)(App)
