import React, {Component} from 'react'
import './style.scss'
import classNames from 'classnames'

export default class About extends Component {

  render () {
    const articles = this.props.articles.map((article, index) => {
      return <div className={classNames('about__slider-slide')}>
        <CustomCursorLink href={article.article.url}>
          <img src={article.thumbnail.url} alt='' className='about__slider-image' />
        </CustomCursorLink>
      </div>
    })
  
    return <div className={classNames('about')}>
      <div className={classNames('about__slider', 'js-content')}>
        {articles}
      </div>
    </div>
  }
}

class CustomCursorLink extends Component {
  constructor (props) {
    super(props)
    this.state = {
      cursorTransform: '',
      hideCursor: true
    }

    this.wrapperRef = React.createRef()

    this.transformCursor = this.transformCursor.bind(this)
    this.hideCursor = this.hideCursor.bind(this)
    this.showCursor = this.showCursor.bind(this)
  }

  transformCursor (e) {
    const parentRect = this.wrapperRef.current.parentNode.getBoundingClientRect()
    const x = e.clientX - parentRect.left
    const y = e.clientY - parentRect.top
    
    this.setState({
      cursorTransform: `translate(calc(-50% + ${x}px), ${y}px)`
    })
  }

  hideCursor () {
    this.setState({
      hideCursor: true
    })
  }

  showCursor () {
    this.setState({
      hideCursor: false
    })
  }
 
  render () {
    return <a href={this.props.href} target="_blank" className={classNames('custom-cursor-link')} onMouseMove={this.transformCursor} onMouseEnter={this.showCursor} onMouseLeave={this.hideCursor} ref={this.wrapperRef}>
      <div className={classNames('custom-cursor-link__cursor', { 'custom-cursor-link__cursor--hidden': this.state.hideCursor })} style={{ transform: this.state.cursorTransform }}>
        view article
      </div>
      {this.props.children}
    </a>
  }
}