import React from 'react'
import './style.scss'
import classNames from 'classnames'

const CloseIcon = (props) => (
  <svg className={classNames('close-icon', { 'close-icon--parallel': props.parallel })} style={{ stroke: props.color || '#000', strokeWidth: props.strokeWidth || 1 }} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15.59 15.82'>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <g id='Layer_2-2' data-name='Layer 2'>
          <g id='Layer_1-2-2' data-name='Layer 1-2'>
            <g id='Layer_2-2-2' data-name='Layer 2-2'>
              <g id='Layer_2-2-2-2' data-name='Layer 2-2-2'>
                <line className='line-1' x1='0.36' y1='0.35' x2='15.24' y2='15.47' />
                <line className='line-2' x1='0.36' y1='15.47' x2='15.24' y2='0.35' />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CloseIcon
