import anime from 'animejs'

const defaultEasing = 'easeInOutQuart'
export const transitionTime = 800

// Name to describe animation, rather than page
// Add comments to inner timeline actions
export const getHomeEnterAnimation = (node) => {
  const timeline = anime.timeline({
    easing: defaultEasing,
    duration: transitionTime,
    autoplay: false
  })

  timeline.add({
    targets: node,
    duration: 0,
    translateY: '-100%'
  })

  timeline.add({
    targets: node,
    translateY: '0'
  })

  return timeline
}

export const getDefaultEnterAnimation = (node) => {
  node.style.transform = 'none'
  node.style.zIndex = ''

  const timeline = anime.timeline({
    easing: defaultEasing,
    duration: transitionTime,
    autoplay: false
  })

  return timeline
}

export const play = (pathname, node, appears) => {
  let timeline
  if (pathname === '/') {
    node.style.zIndex = 100
    timeline = getHomeEnterAnimation(node)
  } else {
    timeline = getDefaultEnterAnimation(node)
  }

  // Leave comment explaining this/link to article
  window
    .loadPromise
    .then(() => timeline.play())
}

// Maybe refactor the exit animations as seperate functions
export const exit = (pathname, node) => {
  const timeline = anime.timeline({
    easing: defaultEasing,
    duration: transitionTime,
    autoplay: true
  })

  if (pathname === '/') {
    node.style.zIndex = 100
    timeline.add({
      targets: node,
      translateY: '-100%'
    })
  } else {
    node.style.overflow = 'hidden'
    node.style.zIndex = 99
    timeline.add({
      targets: node,
      translateX: '100%'
    })

    // Explain js-content
    timeline.add({
      targets: node.querySelectorAll('.js-content'),
      translateX: '-100%'
    }, `-=${transitionTime}`)
  }

  timeline.play()
}
