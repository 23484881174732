
import React, { Component } from 'react'
import anime from 'animejs'

import { Link, Route, Switch, matchPath } from 'react-router-dom'
import ProjectDetail from '../../components/project-detail'
import { TransitionGroup } from 'react-transition-group'
import Transition from 'react-transition-group/Transition'
import './style.scss'
import classNames from 'classnames'

import { getHomeEnterAnimation } from '../../timelines'

const ProjectsGrid = ({ featuredProjects, activeProject, handleMouseEnter, handleMouseLeave }) => {
  return featuredProjects.map((project) => {
    const titleCardActive = activeProject && (activeProject === project.title)
    const detailLink = `projects/${encodeURIComponent(project.title)}`
    const projectImage = project.images[0] && project.images[0].image && project.images[0].image.url

    return (<Link key={project.title} className={classNames('projects-grid__item')} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} to={detailLink} >
      <img className={classNames('projects-grid__item-image')} src={projectImage} alt='' />
      <div className={classNames('projects-grid__item-title-card', { 'projects-grid__item-title-card--active': titleCardActive })}>
        <span>{project.title}</span>
      </div>
    </Link>)
  })
}

ProjectsGrid.defaultProps = {
  featuredProjects: []
}

export default class Projects extends Component {
  constructor () {
    super()
    this.state = {
      titleCardActive: false,
      projectDetailOpen: false
    }

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)

    this.transitionTime = 500
  }

  componentDidMount () {
    if (matchPath(`${this.props.location.pathname}`, {
      path: `${this.props.match.path}/:title`
    })) {
      this.setState({ projectDetailOpen: true })
    }
  }

  prepareSlides = (project) => {
    const slides = []
    if (project && project.images) {
      project.images.map((imageData) => {
        if (imageData.image) {
          slides.push({
            src: imageData.image.url,
            bgColor: 'transparent'
          })
        }
      })
    }
    return slides
  }

  handleMouseEnter = (e) => {
    const activeProjectTitle = decodeURIComponent(e.currentTarget.href.split('/').slice(-1).pop())
    this.setState({ activeProject: activeProjectTitle })
  }

  handleMouseLeave = (e) => {
    this.setState({ activeProject: null })
  }

  // No need for pathname and project params
  animateProjectDetail (pathname, node, project, exit) {
    const defaultEasing = 'easeInOutQuart'
    const transitionTime = 800 // import

    if (node) {
      let timeline = null
      if (!exit) {
        timeline = getHomeEnterAnimation(node)
      } else { // Refactor to import exit animation
        timeline = anime.timeline({
          easing: defaultEasing,
          duration: transitionTime,
          autoplay: true
        })
        node.style.zIndex = 100
        timeline.add({
          targets: node,
          translateY: '-100%'
        })
      }
      window
        .loadPromise
        .then(() => {
          timeline.play()
          if (exit) {
            timeline.finished.then(() => {
              this.setState({
                projectDetailOpen: false
              })
            })
          } else {
            timeline.finished.then(() => {
              this.setState({
                projectDetailOpen: true
              })
            })
          }
        })
    }
  }
  render () {
    return (
      <div className={classNames('projects', { 'projects--no-scroll': this.state.projectDetailOpen })}>
        <div className={classNames('projects-grid', 'js-content')} >
          <ProjectsGrid featuredProjects={this.props.projects} activeProject={this.state.activeProject} handleMouseEnter={this.handleMouseEnter} handleMouseLeave={this.handleMouseLeave} />
        </div>
        {/* No need for this outer route, project can just be defined inside inner route. Might not work for exit animation from project detail to projects */}
        <Route path={`${this.props.match.path}/:title?`} render={props => {
          const project = this.props.projects.find(project => project.title === props.match.params.title)
          const projectSlides = this.prepareSlides(project)

          return (<TransitionGroup component={null}>
            <Transition
              key={props.location.key}
              appear={this.state.appear}
              onEnter={(node) => { this.animateProjectDetail(props.location.pathname, node, project, false) }}
              onExit={(node) => { this.animateProjectDetail(props.location.pathname, node, project, true) }}
              timeout={this.transitionTime}>
              <Switch location={props.location}>
                <Route path={`${this.props.match.path}/:title`} render={(props) => {
                  return <div className={classNames('projects__project-detail')}>
                    <ProjectDetail project={project} slides={projectSlides} />
                  </div>
                }} />
              </Switch>
            </Transition>
          </TransitionGroup>)
        }} />
      </div>
    )
  }
}
